import React, { useState, useEffect } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { motion } from 'framer-motion';
import ContactModal from './ContactModal';
import confetti from 'canvas-confetti';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const InsuranceGraphs: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleGetQuote = () => {
    setIsModalOpen(true);
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  const barChartData = {
    labels: ['Home', 'Auto', 'Life'],
    datasets: [
      {
        label: 'Average Annual Premium',
        data: [1863, 1810, 455],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        borderColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        borderWidth: 1,
      },
    ],
  };

  const pieChartData = {
    labels: ['Auto (40%)', 'Home (25%)', 'Life (15%)', 'Health (15%)', 'Other (5%)'],
    datasets: [
      {
        data: [40, 25, 15, 15, 5],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      },
    ],
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Average Annual Insurance Premiums in Texas',
        font: {
          size: isMobile ? 14 : 16,
          weight: 'bold' as const,
        },
        color: '#1D3557',
        padding: {
          top: 10,
          bottom: 20
        }
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            return `$${context.raw}`;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: isMobile ? 10 : 12,
            weight: 'bold' as const,
          },
          color: '#1D3557',
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          color: '#E0E0E0',
        },
        ticks: {
          font: {
            size: isMobile ? 10 : 12,
          },
          color: '#1D3557',
          callback: function(value: any) {
            return '$' + value;
          }
        }
      }
    },
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          font: {
            size: isMobile ? 10 : 12
          },
          padding: 15
        }
      },
      title: {
        display: true,
        text: 'Distribution of Insurance Types in Texas',
        font: {
          size: isMobile ? 14 : 16,
          weight: 'bold' as const,
        },
        color: '#1D3557',
        padding: {
          top: 10,
          bottom: 20
        }
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            return `${context.label}: ${context.raw}%`;
          }
        }
      }
    }
  };

  return (
    <>
      <div className="bg-[#A8DADC] py-8 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center"
          >
            <h2 className="text-2xl sm:text-3xl font-extrabold text-[#1D3557] sm:text-4xl">
              Texas Insurance Insights
            </h2>
            <p className="mt-4 text-lg sm:text-xl text-[#457B9D]">
              Explore the latest data on insurance trends in the Lone Star State
            </p>
          </motion.div>

          <div className="mt-8 sm:mt-12 grid grid-cols-1 gap-8 md:grid-cols-2">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="bg-white p-4 sm:p-6 rounded-lg shadow-lg flex flex-col"
            >
              <div className="flex-grow" style={{ height: isMobile ? '250px' : '350px' }}>
                <Bar 
                  data={barChartData} 
                  options={barChartOptions} 
                  plugins={[{
                    id: 'barLabels',
                    afterDraw: (chart: any) => {
                      const ctx = chart.ctx;
                      chart.data.datasets.forEach((dataset: any, i: number) => {
                        const meta = chart.getDatasetMeta(i);
                        if (!meta.hidden) {
                          meta.data.forEach((bar: any, index: number) => {
                            const data = dataset.data[index];
                            ctx.fillStyle = '#1D3557';
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'bottom';
                            ctx.font = `bold ${isMobile ? '10px' : '12px'} Arial`;
                            ctx.fillText('$' + data, bar.x, bar.y - 5);
                          });
                        }
                      });
                    }
                  }]}
                />
              </div>
              <div className="mt-4 text-sm text-center text-[#457B9D]">
                Average annual premiums for different insurance types in Texas
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="bg-white p-4 sm:p-6 rounded-lg shadow-lg flex flex-col"
            >
              <div className="flex-grow" style={{ height: isMobile ? '250px' : '350px' }}>
                <Pie data={pieChartData} options={pieChartOptions} />
              </div>
              <p className="mt-4 text-sm text-center text-[#457B9D]">
                Percentage breakdown of different insurance types purchased by Texans
              </p>
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className="mt-8 sm:mt-12 text-center"
          >
            <h3 className="text-xl sm:text-2xl font-bold text-[#1D3557]">Did You Know?</h3>
            <ul className="mt-4 text-base sm:text-lg text-[#457B9D] list-disc list-inside">
              <li>Texas has the second-highest auto insurance rates in the United States.</li>
              <li>Homeowners insurance in Texas is about 50% higher than the national average due to natural disaster risks.</li>
              <li>Nearly 1 in 5 Texans lacks health insurance, the highest rate of any state.</li>
            </ul>
            <p className="mt-4 sm:mt-6 text-xs sm:text-sm text-[#1D3557]">
              Sources: Texas Department of Insurance, Insurance Information Institute, U.S. Census Bureau (2021)
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.8 }}
            className="mt-8 sm:mt-16 bg-white p-6 sm:p-8 rounded-lg shadow-lg"
          >
            <h3 className="text-xl sm:text-2xl font-bold text-[#1D3557] text-center">Why Choose Texas All in One Insurance?</h3>
            <ul className="mt-4 sm:mt-6 text-base sm:text-lg text-[#457B9D] list-none space-y-4">
              <li className="flex items-start">
                <svg className="h-6 w-6 text-[#E63946] mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <span><strong>Texas-Tailored Coverage:</strong> Our policies are specifically designed to address the unique risks faced by Texans, from hurricane protection to hail damage coverage.</span>
              </li>
              <li className="flex items-start">
                <svg className="h-6 w-6 text-[#E63946] mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <span><strong>Competitive Rates:</strong> We leverage our deep understanding of the Texas insurance market to offer competitive rates without compromising on coverage.</span>
              </li>
              <li className="flex items-start">
                <svg className="h-6 w-6 text-[#E63946] mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <span><strong>Bundled Savings:</strong> Save up to 25% when you bundle your home, auto, and life insurance policies with us.</span>
              </li>
              <li className="flex items-start">
                <svg className="h-6 w-6 text-[#E63946] mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <span><strong>Local Expertise:</strong> Our team of Texas-based agents understands the local landscape and can provide personalized advice to meet your specific needs.</span>
              </li>
            </ul>
            <div className="mt-6 sm:mt-8 text-center">
              <button
                onClick={handleGetQuote}
                className="bg-[#E63946] hover:bg-[#1D3557] text-white font-bold py-2 px-4 sm:py-3 sm:px-6 rounded-full transition duration-300 transform hover:scale-105"
              >
                Get Your Personalized Quote Today
              </button>
            </div>
          </motion.div>
        </div>
      </div>
      <ContactModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export default InsuranceGraphs;