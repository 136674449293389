import React, { useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ContactModal from './ContactModal';
import confetti from 'canvas-confetti';
import TexasLoneStar from './TexasLoneStar';

interface NavbarProps {
  isVisible: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ isVisible }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navbarRef = useRef<HTMLDivElement>(null);

  const closeMenu = () => setIsMenuOpen(false);

  const handleGetQuote = () => {
    setIsModalOpen(true);
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  return (
    <>
      <nav ref={navbarRef} className={`bg-[#1D3557] shadow-md fixed w-full transition-transform duration-300 z-50 ${isVisible ? 'translate-y-0' : '-translate-y-full'}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="text-xl font-bold text-[#F1FAEE] flex items-center">
                Texas All in One Insurance
                <TexasLoneStar className="ml-2 text-[#F1FAEE] w-6 h-6" />
              </Link>
            </div>
            <div className="hidden md:flex items-center space-x-4">
              <NavLink to="/#home">Home</NavLink>
              <NavLink to="/#about">About</NavLink>
              <NavLink to="/#insurance-insights">Insurance Insights</NavLink>
              <NavLink to="/#contact">Contact</NavLink>
              <button
                onClick={handleGetQuote}
                className="bg-[#E63946] hover:bg-[#A8DADC] text-[#F1FAEE] hover:text-[#1D3557] font-bold py-2 px-4 rounded transition duration-300"
              >
                Get a Quote
              </button>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-[#F1FAEE] hover:text-[#A8DADC] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#1D3557] focus:ring-white"
              >
                <span className="sr-only">Open main menu</span>
                {!isMenuOpen ? (
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                ) : (
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <div 
          className={`md:hidden fixed top-16 left-0 w-full bg-[#1D3557] shadow-md transition-all duration-300 ease-in-out ${
            isMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
          } overflow-hidden z-40`}
        >
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <NavLink to="/#home" mobile onClick={closeMenu}>Home</NavLink>
            <NavLink to="/#about" mobile onClick={closeMenu}>About</NavLink>
            <NavLink to="/#insurance-insights" mobile onClick={closeMenu}>Insurance Insights</NavLink>
            <NavLink to="/#contact" mobile onClick={closeMenu}>Contact</NavLink>
          </div>
          <div className="pt-4 pb-3 border-t border-[#A8DADC]">
            <button
              onClick={() => {
                handleGetQuote();
                closeMenu();
              }}
              className="w-full bg-[#E63946] hover:bg-[#A8DADC] text-[#F1FAEE] hover:text-[#1D3557] font-bold py-2 px-4 rounded transition duration-300"
            >
              Get a Quote
            </button>
          </div>
        </div>
      </nav>
      <ContactModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  mobile?: boolean;
  onClick?: () => void;
}

const NavLink: React.FC<NavLinkProps> = ({ to, children, mobile, onClick }) => {
  const baseClasses = "text-[#F1FAEE] hover:bg-[#A8DADC] hover:text-[#1D3557] px-3 py-2 rounded-md text-sm font-medium transition duration-300";
  const mobileClasses = mobile ? "block" : "";
  const location = useLocation();
  const navigate = useNavigate();
  
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (onClick) onClick();

    const targetId = to.substring(2); // Remove '/#' from the beginning
    if (location.pathname === '/') {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        const navbarHeight = document.querySelector('nav')?.offsetHeight || 0;
        const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - navbarHeight;
        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth'
        });
      }
    } else {
      navigate('/');
      setTimeout(() => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          const navbarHeight = document.querySelector('nav')?.offsetHeight || 0;
          const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - navbarHeight;
          window.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
          });
        }
      }, 100);
    }
  };
  
  return (
    <a href={to} className={`${baseClasses} ${mobileClasses}`} onClick={handleClick}>
      {children}
    </a>
  );
};

export default Navbar;