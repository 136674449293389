import React, { useState, useEffect } from 'react';
import ContactModal from './ContactModal';
import confetti from 'canvas-confetti';

const TypedText: React.FC<{ 
  text: string; 
  delay?: number; 
  onComplete?: () => void;
  startTyping: boolean;
}> = ({ text, delay = 100, onComplete, startTyping }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (startTyping && currentIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayText(prev => prev + text[currentIndex]);
        setCurrentIndex(prev => prev + 1);
      }, delay);
      return () => clearTimeout(timer);
    } else if (startTyping && currentIndex === text.length) {
      if (onComplete) onComplete();
    }
  }, [text, delay, currentIndex, onComplete, startTyping]);

  return <span>{displayText}</span>;
};

const Hero: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [topLineComplete, setTopLineComplete] = useState(false);

  const handleGetQuote = () => {
    setIsModalOpen(true);
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  const handleLearnMore = () => {
    const aboutSection = document.getElementById('about');
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="bg-[#F1FAEE] py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold leading-tight">
            <span className="block text-[#1D3557] h-16">
              <TypedText 
                text="Protect What Matters Most"
                delay={80}
                onComplete={() => setTopLineComplete(true)}
                startTyping={true}
              />
            </span>
            <span className="block text-[#E63946] mt-2 h-16">
              <TypedText 
                text="in The Lone Star State"
                delay={80}
                startTyping={topLineComplete}
              />
            </span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base sm:text-lg md:mt-5 md:text-xl text-[#457B9D] leading-relaxed">
            Comprehensive home and auto insurance tailored for Texans. From the Alamo City to the Gulf Coast, we've got you covered.
          </p>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <div className="rounded-md shadow">
              <button onClick={handleGetQuote} className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-[#F1FAEE] bg-[#E63946] hover:bg-[#457B9D] md:py-4 md:text-lg md:px-10 transition duration-300">
                Get a Quote
              </button>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <button onClick={handleLearnMore} className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-[#1D3557] bg-[#A8DADC] hover:bg-[#457B9D] hover:text-[#F1FAEE] md:py-4 md:text-lg md:px-10 transition duration-300">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 max-w-7xl mx-auto">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <FeatureCard
            title="Home Insurance"
            description="Protect your Texas home from hurricanes to hailstorms."
            icon={<HomeIcon />}
          />
          <FeatureCard
            title="Auto Insurance"
            description="Coverage for your vehicles on Texas highways and byways."
            icon={<CarIcon />}
          />
          <FeatureCard
            title="Texan-Tailored Policies"
            description="Insurance solutions as unique as the Lone Star State."
            icon={<StarIcon />}
          />
        </div>
      </div>
      <ContactModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

const FeatureCard: React.FC<{ title: string; description: string; icon: React.ReactNode }> = ({ title, description, icon }) => {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg p-5">
      <div className="flex items-center">
        <div className="flex-shrink-0 text-[#E63946]">{icon}</div>
        <div className="ml-5 w-0 flex-1">
          <dt className="text-lg font-medium text-[#1D3557]">{title}</dt>
          <dd className="mt-2 text-base text-[#457B9D]">{description}</dd>
        </div>
      </div>
    </div>
  );
};

const HomeIcon: React.FC = () => (
  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
  </svg>
);

const CarIcon: React.FC = () => (
  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
  </svg>
);

const StarIcon: React.FC = () => (
  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
  </svg>
);

export default Hero;