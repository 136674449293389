import React from 'react';

const Partners: React.FC = () => {
    const partners = [
        { name: 'Progressive', logo: 'https://upload.wikimedia.org/wikipedia/commons/d/d8/Logo_of_the_Progressive_Corporation.svg' },
        { name: 'Geico', logo: 'https://upload.wikimedia.org/wikipedia/commons/d/d2/Geico_logo.svg' },
        { name: 'Safeco', logo: 'https://upload.wikimedia.org/wikipedia/commons/8/80/Safeco_logo.svg' },
        { name: 'Nationwide', logo: 'https://upload.wikimedia.org/wikipedia/commons/6/6f/Nationwide_Insurance.svg' },
    ];

    return (
        <div className="bg-[#F1FAEE] py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <h2 className="text-2xl font-semibold text-[#1D3557] text-center mb-8">Our Trusted Partners</h2>
                <div className="relative overflow-hidden">
                    <div className="flex animate-marquee">
                        {partners.concat(partners).map((partner, index) => (
                            <div key={index} className="flex-shrink-0 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 px-4 py-2">
                                <img src={partner.logo} alt={partner.name} className="w-full h-auto object-contain" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partners;