import React from 'react';

interface TexasLoneStarProps {
  className?: string;
}

const TexasLoneStar: React.FC<TexasLoneStarProps> = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="100"
      height="100"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        {/* Define a linear gradient for the star */}
        <linearGradient id="starGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#BF0A30" /> {/* Red */}
          <stop offset="50%" stopColor="#FFFFFF" /> {/* White */}
          <stop offset="100%" stopColor="#002868" /> {/* Blue */}
        </linearGradient>
      </defs>
      {/* Texas Lone Star with gradient fill */}
      <polygon
        points="50,5 61,35 95,35 67,57 76,90 50,70 24,90 33,57 5,35 39,35"
        fill="url(#starGradient)"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
};

export default TexasLoneStar;
