import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="bg-[#1D3557]">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-[#A8DADC] tracking-wider uppercase">Services</h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/" className="text-base text-[#F1FAEE] hover:text-[#E63946]">
                      Auto Insurance
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="text-base text-[#F1FAEE] hover:text-[#E63946]">
                      Home Insurance
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="text-base text-[#F1FAEE] hover:text-[#E63946]">
                      Renters Insurance
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="text-base text-[#F1FAEE] hover:text-[#E63946]">
                      Business Insurance
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-[#A8DADC] tracking-wider uppercase">Legal</h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/privacy-policy" className="text-base text-[#F1FAEE] hover:text-[#E63946]">
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-[#457B9D] pt-8 md:flex md:items-center md:justify-between">
          <p className="mt-8 text-base text-[#F1FAEE] md:mt-0 md:order-1">
            &copy; 2024 Texas All in One Insurance. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;