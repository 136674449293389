import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <p className="mb-4">
        At Texas All-In-One Insurance, we are committed to protecting your personal information and ensuring transparency about how we use your data. This privacy policy applies to our SMS messaging campaigns, outlining how we collect, use, and protect your personal information.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Personal Information Collection</h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-2">We may collect your phone number and other personal information when you sign up for SMS messaging campaigns, either through our website, social media platforms, or in-person interactions.</li>
        <li className="mb-2">By providing your phone number, you consent to receiving SMS messages from Texas All-In-One Insurance, including, but not limited to, promotional messages, service updates, appointment reminders, and other relevant communications.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Use of Personal Information</h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-2">Your personal information will be used solely for the purpose of delivering the services and messages for which you have opted in.</li>
        <li className="mb-2">"No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties."</li>
        <li className="mb-2">Your data will be securely stored and only accessed by authorized personnel responsible for managing the SMS campaigns.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Opt-in and Opt-out Options</h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-2">You have the right to opt out of our SMS messaging campaigns at any time. To stop receiving messages, simply reply with the word "STOP" to any message you receive from us. After opting out, you will no longer receive SMS messages from Texas All-In-One Insurance.</li>
        <li className="mb-2">You can also reply with "HELP" at any time for assistance or more information regarding our SMS services.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Third-party Data Sharing</h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-2">We do not share your mobile number or any personal information with third parties or affiliates for promotional or marketing purposes.</li>
        <li className="mb-2">In cases where we collaborate with third-party vendors for the management of SMS campaigns, such vendors are required to follow strict confidentiality and security protocols to protect your data.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Consent</h2>
      <p className="mb-4">
        By providing your mobile phone number and opting into our SMS messaging services, you agree to this privacy policy and understand that message and data rates may apply. Message frequency may vary depending on the type of service or promotion.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Changes to the Privacy Policy</h2>
      <p className="mb-4">
        We reserve the right to update or modify this privacy policy at any time. Changes will be posted on our website, and your continued use of our SMS services will indicate your acceptance of those changes.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Contact Us</h2>
      <p className="mb-4">
        If you have any questions about this Privacy Policy, please contact us at Shani.tejani@trutela.com.
      </p>
    </div>
  );
};

export default PrivacyPolicy;