import React, { useState } from 'react';
import ContactModal from './ContactModal';
import confetti from 'canvas-confetti';

const Contact: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false); // Changed to false to prevent modal from opening on page load

    const openModal = () => {
        setIsModalOpen(true);
        confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
        });
    };
    const closeModal = () => setIsModalOpen(false);

    return (
        <section className="relative bg-[#1D3557] py-20 px-4 sm:px-6 lg:px-8" aria-labelledby="contact-heading">
            <div className="absolute inset-0">
                <div className="absolute inset-0 bg-gradient-to-r from-[#1D3557] to-[#457B9D]" style={{ mixBlendMode: 'multiply' }} aria-hidden="true" />
            </div>
            <div className="relative max-w-7xl mx-auto">
                <header className="text-center">
                    <h2 id="contact-heading" className="text-3xl font-extrabold tracking-tight text-[#F1FAEE] sm:text-4xl">
                        Get in Touch with Texas All in One Insurance
                    </h2>
                    <p className="mt-6 text-lg text-[#A8DADC]">
                        Have questions or ready to get covered? We're here to help, partner!
                    </p>
                </header>
                <div className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-12">
                    <div className="space-y-8">
                        <address className="not-italic">
                            <h3 className="text-xl font-semibold text-[#F1FAEE] mb-2">Our Location</h3>
                            <p className="text-[#A8DADC]">21232 Gathering Oak Ste 107,<br />San Antonio, TX 78260</p>
                        </address>
                        <div>
                            <h3 className="text-xl font-semibold text-[#F1FAEE] mb-2">Office Hours</h3>
                            <p className="text-[#A8DADC]">9:00 AM - 5:00 PM CST<br />Monday - Friday</p>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold text-[#F1FAEE] mb-2">Phone</h3>
                            <p className="text-[#A8DADC]"><a href="tel:2103054999" aria-label="Call our office">210-305-4999</a></p>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold text-[#F1FAEE] mb-2">E-Mail</h3>
                            <p className="text-[#A8DADC]">
                                <a href="mailto:Shani.tejani@trutela.com" aria-label="Email Shani Tejani">Shani.tejani@trutela.com</a><br />
                                <a href="mailto:Alex.Fernandez@trutela.com" aria-label="Email Alex Fernandez">Alex.Fernandez@trutela.com</a>
                            </p>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold text-[#F1FAEE] mb-2">Fax</h3>
                            <p className="text-[#A8DADC]">210-693-1112</p>
                        </div>
                        <button
                            onClick={openModal}
                            className="w-full sm:w-auto inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-[#F1FAEE] bg-[#E63946] hover:bg-[#A8DADC] hover:text-[#1D3557] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#E63946] transition duration-300"
                            aria-label="Open contact form"
                        >
                            Contact Us
                        </button>
                    </div>
                    <div className="h-80 md:h-full">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3472.8283307821125!2d-98.4815698!3d29.6434749!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865cbd2d48a7e183%3A0x8f70ab8f51dd31c2!2s21232%20Gathering%20Oak%20Ste%20107%2C%20San%20Antonio%2C%20TX%2078260%2C%20USA!5e0!3m2!1sen!2sus!4v1696600000&z=12"
                            width="100%"
                            height="100%"
                            style={{ border: 0 }}
                            allowFullScreen={true}
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="Office Location"
                            aria-label="Google Maps showing our office location"
                        ></iframe>
                    </div>
                </div>
            </div>
            <ContactModal isOpen={isModalOpen} onClose={closeModal} />
        </section>
    );
};

export default Contact;