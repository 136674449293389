import React, { useState, useEffect } from 'react';

interface ContactModalProps {
    isOpen: boolean;
    onClose: () => void;
}

type ContactMethod = 'call' | 'text' | 'email' | null;

const ContactModal: React.FC<ContactModalProps> = ({ isOpen, onClose }) => {
    const [contactMethod, setContactMethod] = useState<ContactMethod>(null);
    const [name, setName] = useState('');
    const [insuranceType, setInsuranceType] = useState('');
    const [privacyPolicyAcknowledged, setPrivacyPolicyAcknowledged] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            // Reset state when modal is closed
            setContactMethod(null);
            setName('');
            setInsuranceType('');
            setPrivacyPolicyAcknowledged(false);
        }
    }, [isOpen]);

    if (!isOpen) return null;

    const handleContactMethodChange = (method: ContactMethod) => {
        setContactMethod(method);
        if (method === 'call') {
            window.location.href = 'tel:+12103054999';
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!privacyPolicyAcknowledged) {
            alert("Please acknowledge the privacy policy before submitting.");
            return;
        }
        if (contactMethod === 'text') {
            const message = encodeURIComponent(`Name: ${name}\nInsurance Type: ${insuranceType}\n\nHi, I'm interested in getting a quote for insurance.`);
            window.location.href = `sms:+12103054999?body=${message}`;
        } else if (contactMethod === 'email') {
            const subject = encodeURIComponent('Insurance Quote Request');
            const body = encodeURIComponent(`Name: ${name}\nInsurance Type: ${insuranceType}\n\nHi, I'm interested in getting a quote for insurance.`);
            window.location.href = `mailto:Shani.tejani@trutela.com?subject=${subject}&body=${body}`;
        }
    };

    const renderInputFields = () => (
        <form onSubmit={handleSubmit} className="mt-4 space-y-4">
            <input
                type="text"
                placeholder="Your Name"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
            />
            <select
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={insuranceType}
                onChange={(e) => setInsuranceType(e.target.value)}
                required
            >
                <option value="">Select Insurance Type</option>
                <option value="Auto">Auto Insurance</option>
                <option value="Home">Home Insurance</option>
                <option value="Life">Life Insurance</option>
            </select>
            <div className="flex items-center">
                <input
                    type="checkbox"
                    id="privacyPolicy"
                    className="mr-2"
                    checked={privacyPolicyAcknowledged}
                    onChange={(e) => setPrivacyPolicyAcknowledged(e.target.checked)}
                    required
                />
                <label htmlFor="privacyPolicy" className="text-sm text-gray-700">
                    I acknowledge the <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">privacy policy</a>
                </label>
            </div>
            <button
                type="submit"
                className="w-full px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
            >
                Submit
            </button>
        </form>
    );

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <div className="mt-3 text-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Contact Options</h3>
                    <div className="mt-2 px-7 py-3">
                        <p className="text-sm text-gray-500">
                            Choose how you'd like to get in touch with us:
                        </p>
                    </div>
                    <div className="flex flex-col space-y-4 mt-4">
                        <div>
                            <button
                                onClick={() => handleContactMethodChange('call')}
                                className="w-full px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                            >
                                Call Us
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={() => handleContactMethodChange('text')}
                                className="w-full px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300"
                            >
                                Text Us
                            </button>
                            {contactMethod === 'text' && renderInputFields()}
                        </div>
                        <div>
                            <button
                                onClick={() => handleContactMethodChange('email')}
                                className="w-full px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                            >
                                Email Us
                            </button>
                            {contactMethod === 'email' && renderInputFields()}
                        </div>
                    </div>
                    <div className="items-center px-4 py-3 mt-4">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactModal;